module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://ghost.justin.palpant.us","contentApiKey":"d7c8c0b681eb11c253c580576b"},"production":{"apiUrl":"https://ghost.justin.palpant.us","contentApiKey":"d7c8c0b681eb11c253c580576b"}},"siteConfig":{"siteUrl":"https://justin.palpant.us","infiniteScroll":true,"postsPerPage":3,"siteTitleMeta":"Justin Palpant - a star shines upon the hour of our meeting","siteDescriptionMeta":"My personal blog","shareImageWidth":1000,"shareImageHeight":523,"shortTitle":"Justin Palpant Blog","siteIcon":"favicon_128.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","verbose":true,"severity":"info"},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
